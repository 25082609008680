<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Sample Preparation – Grinding" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/BKNO3-Before-e1447105400502-min.jpg"
          width="400"
          height="400"
          alt=""
          title="BKNO3 Before Grinding"
          class="img-responsive wp-image-12138 mb-6"
        />
        <img
          src="@/assets/Testing/BKNO3-After-e1447105333173-min.jpg"
          width="400"
          height="400"
          alt=""
          title="BKNO3 After Grinding"
          class="img-responsive wp-image-12139"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Sometimes clients require small particle sizes for testing purposes.
            SMS can provide grinding services of energetic and inert materials
            for testing.&nbsp; Typical particle sizes will pass through a 0.5mm
            sieve screen.&nbsp; Material prepared can be used for sensitivity
            testing (e.g., impact, friction and ESD) as sensitivity test results
            typically change when particle size changes. &nbsp;Examples of
            grinding BKNO<sub>3</sub> (before and after) are shown.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Grinding Services",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can provide grinding services to prepare materials in preparation for sensitivity testing."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
